<template>
  <b-modal
    id="add-page-popup"
    :visible="visible"
    size="lg"
    ok-only
    ok-title="Сохранить"
    title="Создание страницы"
    @ok="clickOnSave"
    @hidden="$emit('hidden')"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <div class="mb-3">
        <div class="mb-3">
          <b-form-checkbox
            v-model="page.is_published"
            switch
          >
            Публикация активна
          </b-form-checkbox>
        </div>
        <b-form-group
          label="Название публикации"
          label-for="title"
        >
          <b-form-input
            id="title"
            v-model="page.title"
            v-validate="'required'"
            name="title"
          />
          <small
            v-show="errors.has('title')"
            class="text-danger"
          >{{ errors.first('title') }}</small>
        </b-form-group>
        <b-form-group
          label="URL (если оставить пустым, будет автоматически установлен)"
          label-for="slug"
        >
          <b-form-input
            id="slug"
            v-model="page.slug"
            name="slug"
          />
          <small
            v-show="errors.has('slug')"
            class="text-danger"
          >{{ errors.first('slug') }}</small>
        </b-form-group>
        <b-form-group
          label="Breadcrumb title"
          label-for="breadcrumb"
        >
          <b-form-input
            id="breadcrumb"
            v-model="page.breadcrumb"
            name="breadcrumb"
          />
          <small
            v-show="errors.has('breadcrumb')"
            class="text-danger"
          >{{ errors.first('breadcrumb') }}</small>
        </b-form-group>
      </div>

      <b-tabs
        pills
      >
        <b-tab active>
          <template #title>
            <span class="mr-1">
              <feather-icon
                icon="TagIcon"
              />
              Мета-теги
            </span>
          </template>
          <div class="py-2">
            <b-form-group
              label="Title"
              label-for="meta_title"
            >
              <b-form-input
                id="meta_title"
                v-model="page.meta_title"
                name="meta_title"
              />
              <small
                v-show="errors.has('meta_title')"
                class="text-danger"
              >{{ errors.first('meta_title') }}</small>
            </b-form-group>
            <b-form-group
              label="Description"
              label-for="meta_description"
            >
              <b-form-textarea
                id="meta_description"
                v-model="page.meta_description"
                name="meta_description"
                rows="4"
                no-resize
              />
              <small
                v-show="errors.has('meta_description')"
                class="text-danger"
              >{{ errors.first('meta_description') }}</small>
            </b-form-group>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <span class="mr-1">
              <feather-icon
                icon="LayoutIcon"
              />
              Контент
            </span>
          </template>
          <div class="py-2">
            <b-form-group
              label="Обложка"
            >
              <input-file
                v-model="page.cover_image_key"
                v-validate="'required'"
                size="lg"
                name="cover_image_key"
              />
              <small
                v-show="errors.has('cover_image_key')"
                class="text-danger"
              >{{ errors.first('cover_image_key') }}</small>
            </b-form-group>
            <b-form-group
              label="Описание для карточки блога"
              label-for="content_preview"
            >
              <b-form-input
                id="content_preview"
                v-model="page.content_preview"
                name="content_preview"
              />
              <small
                v-show="errors.has('content_preview')"
                class="text-danger"
              >{{ errors.first('content_preview') }}</small>
            </b-form-group>
            <b-form-group
              label="Контент"
              label-for="content_template"
            >
              <CKEditor
                v-model="page.content"
                v-validate="'required'"
                name="content_template"
              />
              <small
                v-show="errors.has('content_template')"
                class="text-danger"
              >{{ errors.first('content_template') }}</small>
            </b-form-group>
          </div>
        </b-tab>
      </b-tabs>
    </form>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BTabs, BTab, BFormCheckbox, BFormGroup, BFormInput, BFormTextarea,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import mixinErrorsParse from '@core/mixins/ui/mixinErrorsParse'
import CKEditor from 'ckeditor4-vue'

import InputFile from '@core/components/input/InputFile.vue'

import CustomPage from '@/api/http/models/custom-page/CustomPage'
import TranslitRusToLat from '@core/utils/TranslitRusToLat'

export default {
  components: {
    BTabs,
    BTab,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    InputFile,
    CKEditor: CKEditor.component,
  },
  mixins: [mixinErrorsParse],
  data() {
    return {
      visible: false,
      page: {
        slug: '',
        is_published: false,
        title: '',
        meta_title: '',
        meta_description: '',
        breadcrumb: '',
        content_preview: '',
        content: '',
        cover_image_key: '',
      },
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },
  async created() {
    this.visible = true
  },
  methods: {
    async clickOnSave(modalEvent) {
      modalEvent.preventDefault()
      this.handleSubmit()
    },

    async handleSubmit() {
      this.page.slug = TranslitRusToLat(this.page.slug)

      if (this.page.title && !this.page.slug) {
        this.page.slug = TranslitRusToLat(this.page.title)
      }

      const validate = await this.$validator.validateAll()

      if (validate) {
        const response = await CustomPage.add(this.currentSite.slug, this.page)

        if (response.status === 'success' && response.data) {
          this.$emit('added', response.data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Страница добавлена',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$nextTick(() => {
            this.$bvModal.hide('add-page-popup')
          })
        }

        if (response.errors) {
          this.mixinErrorsParse(response.errors)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка при добавлении страницы',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
  },
}
</script>
