<template>
  <b-card>
    <div class="row mb-5">
      <div class="col-12 col-md-3">
        <b-form-input
          id="search"
          v-model="search"
          name="search"
          placeholder="Поиск"
          @keyup.enter="onSearch"
          @input="onInputSearch"
        />
      </div>
      <div class="col-12 col-md-3 d-flex align-items-center">
        <b-form-checkbox
          v-model="is_published"
          @change="onSearch"
        >
          Показать опубликованные
        </b-form-checkbox>
      </div>
    </div>
    <b-button
      class="mb-2 d-flex align-items-center"
      variant="primary"
      @click.prevent="showAddPopup = true"
    >
      <feather-icon
        icon="PlusIcon"
        size="18"
        class="mr-1"
      />
      Добавить страницу
    </b-button>
    <div
      v-if="pages.length"
    >
      <custom-page-list
        :pages="pages"
        @updated="updatePage"
        @deleted="deletePage"
      />
      <b-pagination
        v-if="total / per_page > 1"
        v-model="page"
        :total-rows="total"
        :per-page="per_page"
        first-number
        last-number
        class="mb-0 mt-1 mt-sm-0"
        prev-class="prev-item"
        next-class="next-item"
        align="right"
        @input="onChangePage"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </div>
    <div v-else>
      Нет страниц
    </div>
    <add-custom-page-popup
      v-if="showAddPopup"
      @added="addPage"
      @hidden="showAddPopup = false"
    />
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BCard, BButton, BFormInput, BFormCheckbox, BPagination,
} from 'bootstrap-vue'

import CustomPage from '@/api/http/models/custom-page/CustomPage'

import AddCustomPagePopup from './AddCustomPagePopup.vue'
import CustomPageList from './CustomPageList.vue'

export default {
  components: {
    BCard,
    BButton,
    BFormInput,
    BFormCheckbox,
    BPagination,
    AddCustomPagePopup,
    CustomPageList,
  },
  data() {
    return {
      showAddPopup: false,
      pages: [],
      search: '',
      is_published: false,
      page: 1,
      per_page: 1,
      total: 1,
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },
  async created() {
    this.page = this.$route.query.page || 1
    this.search = this.$route.query.search || ''
    this.is_published = this.$route.query.is_published || 0

    await this.fetchPages()
  },
  methods: {
    async fetchPages() {
      const query = {}

      if (this.page) {
        query.page = this.page
      }

      if (this.search) {
        query.search = this.search
      }

      if (this.is_published) {
        query.is_published = 1
      }

      const response = await CustomPage.getAll(this.currentSite.slug, query)

      this.pages = response.data || []

      this.per_page = response.meta.per_page || 1

      this.total = response.meta.total || 1
    },
    onInputSearch() {
      if (this.search === '') {
        this.onSearch()
      }
    },
    onSearch() {
      const query = {}

      if (this.search) {
        query.search = this.search
      }

      if (this.is_published) {
        query.is_published = 1
      }

      if (
        JSON.stringify(this.$route.query) !== JSON.stringify(query)
      ) {
        this.page = 1

        this.$router.replace({
          query: {
            ...query,
          },
        })

        this.fetchPages()
      }
    },
    onChangePage() {
      this.$router.replace({
        query: {
          ...this.$route.query,
          page: this.page,
        },
      })

      this.fetchPages()
    },
    addPage(page) {
      this.pages.push(page)
    },
    updatePage(page) {
      const index = this.pages.findIndex(cur => cur.slug === page.slug)

      if (index !== -1) {
        this.pages.splice(index, 1, page)
      }
    },
    deletePage(page) {
      const index = this.pages.findIndex(cur => cur.slug === page.slug)

      if (index !== -1) {
        this.pages.splice(index, 1)
      }
    },
  },
}
</script>
