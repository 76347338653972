import HTTPService from '@/api/http/HTTPService'
import URLSearchParams from '@/@core/utils/getUrlParams'

import CustomPageInterface from './CustomPageInterface'

export default {
  getAll: (siteSlug: string, query?: {page?: number, search?: string, is_published?: number}) => {
    const queryString = query ? URLSearchParams(query) : false
    return HTTPService.get(`site/${siteSlug}/custom-page${queryString ? `?${queryString}` : ''}`)
  },
  add: (siteSlug: string, payload: CustomPageInterface) => HTTPService.post(`site/${siteSlug}/custom-page`, payload),
  getOne: (siteSlug: string, customPageSlug: string) => HTTPService.get(`site/${siteSlug}/custom-page/${customPageSlug}`),
  update: (siteSlug: string, customPageSlug: string, payload: CustomPageInterface) => HTTPService.put(`site/${siteSlug}/custom-page/${customPageSlug}`, payload),
  activate: (siteSlug: string, customPageSlug: string, isPublished: boolean) => HTTPService.patch(`site/${siteSlug}/custom-page/${customPageSlug}`, {
    is_published: isPublished,
  }),
  delete: (siteSlug: string, customPageSlug: string) => HTTPService.delete(`site/${siteSlug}/custom-page/${customPageSlug}`),
}
